import React, { useRef } from 'react'
import './Header.css'
import {HiMenu} from 'react-icons/hi'
import {AiFillCloseCircle} from 'react-icons/ai'
const Header = () => {
  const newref = useRef();
  
  const openmenu =()=>{
    console.log(newref.current.style.right)
    newref.current.style.right="0px";

  }
  const closemenu =()=>{
    newref.current.style.right="-150px"
  }
  return (
    <div className='h-container' id='homepage'>
      <div className="h-left">
         <p>
          <font color="orange">P</font>
          <font color="yellow">O</font>
          <font color="#00C1D4">R</font>
          <font color="aqua">T</font>
          <font color="blue">F</font>
          <font color="rgb(248, 9, 212)">O</font>
          <font color="FFCCCC">L</font>
          <font color="WHITE">I</font>
          <font color="red">0</font>
          </p>
      </div>
      <div className="h-right">
        <ul   id='sidemenu' ref={newref} >
            <AiFillCloseCircle className='close-icon' onClick={closemenu} />
            <li><a href="#homepages">Home</a></li>
            <li> <a href="#aboutpage">About</a></li>
            <li><a href="#skillpage">Skills</a></li>
            <li> <a href="#servisepage">Services</a></li>
            <li> <a href="#projectpage">Project</a></li>
            <li><a href="#contactpage">Contact</a></li>
           
        </ul>
        <HiMenu className='menu-icon' onClick={openmenu}/>
        
      </div>
    </div>
  )
}

export default Header
